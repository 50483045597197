import { render, staticRenderFns } from "./support-group-info-page.html?vue&type=template&id=7e9c9174&scoped=true&"
import script from "./support-group-info-page.js?vue&type=script&lang=js&"
export * from "./support-group-info-page.js?vue&type=script&lang=js&"
import style0 from "./support-group-info-page.scss?vue&type=style&index=0&id=7e9c9174&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9c9174",
  null
  
)

export default component.exports